import { Plugin } from 'vue'
import md from '../utils/markdown'

export const markdown: Plugin = {
    install: app => {
        app.config.globalProperties.md = (source: string) => md.render(source)
        app.config.globalProperties.mdi = (source: string) => md.renderInline(source)

        app.directive('md', {
            mounted: (el, binding) => (el.innerHTML = md.render(binding.value ?? '')),
            updated: (el, binding) => (el.innerHTML = md.render(binding.value ?? '')),
        })

        app.directive('mdi', {
            mounted: (el, binding) => (el.innerHTML = md.renderInline(binding.value ?? '')),
            updated: (el, binding) => (el.innerHTML = md.renderInline(binding.value ?? '')),
        })
    },
}
