<script setup>
import { usePage } from '@inertiajs/vue3'
import { isEmpty } from 'lodash-es'
import { computed } from 'vue'

const flashMessages = computed(() => usePage().props.flash)
const hasMessages = computed(() => !isEmpty(Object.values(flashMessages.value).filter(v => v)))
</script>

<template>
    <div v-if="hasMessages" dusk="flash-message" class="pt-2">
        <SuccessAlert v-if="flashMessages.success">{{ flashMessages.success }}</SuccessAlert>
        <WarningAlert v-if="flashMessages.warning">{{ flashMessages.warning }}</WarningAlert>
        <InfoAlert v-if="flashMessages.info">{{ flashMessages.info }}</InfoAlert>
        <ErrorAlert v-if="flashMessages.error">{{ flashMessages.error }}</ErrorAlert>
    </div>
</template>
