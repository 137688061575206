import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { BasePageProps } from '@/scripts/types/pages'

export const usePageTitle = () => {
    return computed(() => {
        const title = usePage<BasePageProps>().props.title
        const companyName = usePage<BasePageProps>().props.auth?.company?.name

        if (title) {
            return companyName ? `${title} - ${companyName}` : title
        }

        return companyName
    })
}
