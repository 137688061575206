export function resolveLayout(defaultLayout, layouts, page) {
    if (page.default.layout && typeof page.default.layout === 'string') {
        page.default.layout = layouts[`../views/layouts/${page.default.layout}.vue`].default
    }
    if (page.default.layout === undefined) {
        page.default.layout = layouts[defaultLayout].default
    }

    return page
}
