<script setup lang="ts">
import { AvatarRoot } from 'radix-vue'
import { type AvatarVariants, avatarVariant } from '.'
import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'

const props = withDefaults(
    defineProps<{
        class?: HTMLAttributes['class']
        size?: AvatarVariants['size']
        shape?: AvatarVariants['shape']
        ring?: AvatarVariants['ring']
    }>(),
    {
        class: '',
        size: 'sm',
        shape: 'circle',
        ring: 'none',
    },
)
</script>

<template>
    <AvatarRoot :class="cn(avatarVariant({ size, shape, ring }), props.class)">
        <slot />
    </AvatarRoot>
</template>
