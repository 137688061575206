interface LanguageJsonFileInterface {
    default: {
        [key: string]: string
    }
}

export default async function (lang: string): Promise<LanguageJsonFileInterface> {
    const langs = import.meta.glob('../../../lang/*.json')

    let resolved = langs[`../../../lang/${lang}.json`]

    if (!resolved) {
        // special handling for Norwegian...
        if (lang === 'nb_NO' || lang === 'nb-NO' || lang === 'nn_NO' || lang === 'nn-NO') {
            resolved = langs[`../../../lang/no.json`]
        }
        else {
            resolved = langs[`../../../lang/${lang.split('-')[0]}.json`]
        }
    }

    return (await resolved) ? resolved() : null
}
