import { usePage } from '@inertiajs/vue3'
import { defineRoutes, store } from 'momentum-trail'
import { RouteCollection } from 'momentum-trail/dist/types/types'
import { watch } from 'vue'

declare global {
    interface Window {
        trail: RouteCollection
    }
}

/**
 * This composable is used by page layout components to watch for changes in URL defaults, which
 * are provided by the HandleInertiaRequests middleware. This allows switching app context from
 * one company to another, or from guest to authenticated user without a full page reload and
 * ensures Trail/Ziggy has the correct URL defaults available at all times.
 */
export const useUrlDefaultsWatcher = () => {
    watch(
        () => usePage().props.urlDefaults,
        (defaults: object) => {
            if (typeof window !== 'undefined' && window.trail) {
                window.trail.defaults = defaults
            } else {
                defineRoutes(Object.assign(store.getRoutes(), defaults))
            }
        },
        { immediate: true },
    )
}
