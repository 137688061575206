<script setup lang="ts">
import { usePageTitle } from '@/scripts/composables/usePageTitle'
import { useUrlDefaultsWatcher } from '@/scripts/composables/useUrlDefaultsWatcher'
import AppBanners from '@/views/layouts/Partials/AppBanners.vue'
import { Modal } from '@vendor/emargareten/inertia-modal'

useUrlDefaultsWatcher()

const pageTitle = usePageTitle()
</script>

<template>
    <Head :title="pageTitle" />

    <div class="h-screen flex flex-col">
        <AppBanners />

        <slot />
    </div>

    <!-- Global notifications -->
    <AppNotifications />

    <!-- Route-based modals -->
    <Modal />
</template>
