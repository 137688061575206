<script setup lang="ts">
import type { BasePageProps } from '@/scripts/types/pages'
import type { RouteName } from 'momentum-trail/dist/types/router'
import { CompanyPermissions, SuperAdminPermissions } from '@/scripts/types/generated'
import { can, canOneOf } from '@/scripts/utils/permissions'
import SliptreeLogo from '@/svg/brand/sliptree-logo.svg'
import SliptreeLogoMark from '@/svg/brand/sliptree-logomark.svg'
import AppTopNavMenuItem from '@/views/components/Shared/AppTopNavMenuItem.vue'
import { Disclosure, DisclosureButton, DisclosurePanel, MenuButton } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { usePage } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import { current, route } from 'momentum-trail'
import { computed } from 'vue'

const user = usePage<BasePageProps>().props.auth.user
const company = usePage<BasePageProps>().props.auth.company

const hasMultipleCompanies = computed(() => user.companies && user.companies.length > 1)
const isCurrentCompany = (companyId: number) => companyId === company?.id
const isCurrentRoute = (routeName: RouteName) => Boolean(usePage().url && current(routeName))

const navMenuItems = computed(() => {
    if (!company) {
        return []
    }

    return [
        {
            name: trans('Dashboard'),
            route: 'company:dashboard',
            class: 'max-lg:hidden',
        },
        {
            name: trans('Invoices'),
            route: 'company:invoices.index',
        },
        {
            name: trans('Estimates'),
            route: 'company:estimates.index',
        },
        {
            name: trans('Customers'),
            route: 'company:customers.index',
        },
        {
            name: trans('Products'),
            route: 'company:products.index',
        },
    ]
})

const homeRoute = computed(() => {
    return company ? route('company:dashboard') : '/app'
})

const profileRoute = computed(() => {
    return company ? route('company:user.profile.show') : route('user.profile.show')
})

function openHelpBeacon() {
    window.Beacon('open')
}
</script>

<template>
    <Disclosure v-slot="{ open }" as="nav" class="bg-white shadow z-50 sticky top-0">
        <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div class="relative flex h-16 justify-between">
                <div class="flex flex-1 items-stretch justify-start">
                    <Link :href="homeRoute" class="flex flex-shrink-0 items-center">
                        <SliptreeLogo class="mx-auto h-8 w-auto fill-current text-pink-500 hidden lg:block" />
                        <SliptreeLogoMark class="mx-auto h-8 w-auto fill-current text-pink-500 lg:hidden" />
                    </Link>

                    <!-- Desktop  -->
                    <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
                        <AppTopNavMenuItem
                            v-for="item in navMenuItems"
                            :key="item.route"
                            :href="route(item.route as RouteName)"
                            :active="isCurrentRoute(item.route.replace('.index', '.*') as RouteName)"
                            :class="item.class ?? ''"
                        >
                            {{ item.name }}
                        </AppTopNavMenuItem>
                    </div>

                    <!-- Mobile  -->
                    <div class="flex items-center pl-2 sm:hidden">
                        <!-- Mobile menu button -->
                        <DisclosureButton
                            class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500"
                        >
                            <span class="absolute -inset-0.5" />
                            <span class="sr-only">Open main menu</span>
                            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                        </DisclosureButton>
                    </div>
                </div>

                <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 gap-2">
                    <!-- Company dropdown -->
                    <Dropdown v-if="company">
                        <template #button>
                            <MenuButton
                                class="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 items-center gap-1"
                            >
                                <span class="absolute -inset-1.5" />
                                <span class="sr-only">Open business menu</span>
                                <span class="ml-2">{{ company?.name }}</span>
                                <ChevronDownIcon class="h-4 w-4 text-gray-500" />
                            </MenuButton>
                        </template>

                        <div class="flex flex-col divide-y *:py-1 first:*:pt-0 last:*:pb-0">
                            <div v-if="canOneOf(CompanyPermissions.UPDATE, CompanyPermissions.MANAGE_BILLING)">
                                <DropdownItem v-if="can(CompanyPermissions.UPDATE)" :href="route('company:account.edit')">
                                    <CogIcon class="h-5 w-5 text-gray-500" />
                                    {{ $t('Business settings') }}
                                </DropdownItem>

                                <DropdownItem v-if="can(CompanyPermissions.MANAGE_BILLING)" :href="route('company:billing')">
                                    <CreditCardIcon class="h-5 w-5 text-gray-500" />
                                    {{ $t('Billing') }}
                                </DropdownItem>
                            </div>

                            <div v-if="hasMultipleCompanies">
                                <span class="text-xs py-3 px-4 font-semibold text-gray-500">{{ $t('Switch company') }}</span>

                                <template v-for="anotherCompany in user.companies">
                                    <DropdownItem
                                        v-if="company.id !== anotherCompany.id"
                                        :key="anotherCompany.id"
                                        as="a"
                                        :href="route('company:dashboard', { company: anotherCompany.slug })"
                                        :active="isCurrentCompany(anotherCompany.id as number)"
                                    >
                                        {{ anotherCompany.name }}
                                    </DropdownItem>
                                </template>
                            </div>

                            <div>
                                <DropdownItem :href="route('companies.create')">
                                    <PlusCircleIcon class="h-5 w-5 text-gray-500" />
                                    {{ $t('Add another business') }}
                                </DropdownItem>
                            </div>
                        </div>
                    </Dropdown>

                    <!-- Profile dropdown -->
                    <Dropdown>
                        <template #button>
                            <MenuButton
                                class="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 items-center gap-2"
                            >
                                <span class="absolute -inset-1.5" />
                                <span class="sr-only">Open user menu for</span>
                                <UserAvatar class="h-8 w-8 rounded-full" />
                            </MenuButton>
                        </template>

                        <div class="px-4 py-3 flex flex-col gap-2 text-sm">
                            <span v-if="user.name" class="font-semibold">{{ user.name }}</span>
                            <span class="text-gray-500">{{ user.email }}</span>
                        </div>

                        <hr class="my-1 border-t">

                        <DropdownItem :href="profileRoute">
                            <UserIcon class="h-5 w-5 text-gray-500" />
                            {{ $t('View profile') }}
                        </DropdownItem>

                        <DropdownItem v-if="can(SuperAdminPermissions.VIEW_ADMIN)" :href="route('filament.admin.pages.dashboard')" external>
                            <ShieldCheckIcon class="h-5 w-5 text-gray-500" />
                            Access Super Admin
                        </DropdownItem>

                        <DropdownItem @click="openHelpBeacon">
                            <LifebuoyIcon class="h-5 w-5 text-gray-500" />
                            {{ $t('Help') }}
                        </DropdownItem>

                        <DropdownItem :href="route('logout')" method="post" as="button">
                            <ArrowRightOnRectangleIcon class="h-5 w-5 text-gray-500" />
                            {{ $t('Log Out') }}
                        </DropdownItem>
                    </Dropdown>
                </div>
            </div>
        </div>

        <DisclosurePanel v-slot="{ close }" class="sm:hidden">
            <div class="space-y-1 pb-4 pt-2">
                <AppTopNavMobileMenuItem
                    v-for="item in navMenuItems"
                    :key="item.route"
                    :href="route(item.route as RouteName)"
                    :active="isCurrentRoute(item.route as RouteName)"
                    @click="close"
                >
                    {{ item.name }}
                </AppTopNavMobileMenuItem>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>
