import { cva, type VariantProps } from 'class-variance-authority'

export { default as Button } from './Button.vue'

/**
 * A brief explanation of the classes and variants used below:
 *
 * - aria-disabled variants are used to disable the button and prevent user interaction, even when using anchor tags (disabled variants do not work on anchors)
 * - [&>*] variants ensure that all direct descendants of the button (like icons, etc.) have their opacity transitioned
 * - data-[loading] variants ensure that the button text notes (not wrapped in any elements) are hidden when the button is in a loading state
 * - [&>*:not(svg)] variants with text color classes on individual button styles/variants ensures that the text-opacity defined on the button does not affect
 *   text nodes inside direct descendants. SVG elements are excluded from this rule, because they usually define their own text colors, and we don't want to override them.
 *
 * Note that the loading spinner opacity and transition styles are defined in Button.vue, not here.
 */
export const buttonVariants = cva(
    `
        leading-5 outline-none font-semibold transition ease-in-out
        text-center justify-center cursor-pointer inline-flex items-center
        border border-transparent rounded-full text-white
        focus:z-10
        aria-disabled:opacity-70
        aria-disabled:pointer-events-none
        [&>*]:transition-opacity
        data-[loading]:text-opacity-0
        data-[loading]:pointer-events-none
        [&[data-loading]>:not([data-loading-indicator])]:opacity-0
        `,
    {
        variants: {
            variant: {
                default: `
                    bg-pink-500
                    text-white
                    focus:ring-2 focus:ring-pink-300 focus:border-pink-600
                    [&>*:not(svg)]:text-white
                    [&:not([disabled])]:hover:bg-pink-400
                    [&:not([disabled])]:active:bg-pink-600
                    `,
                secondary: `
                    bg-gray-100 border-gray-300
                    text-gray-700
                    [&>*:not(svg)]:text-gray-700
                    focus:border-gray-400 focus:ring-2 focus:ring-gray-300
                    [&:not([disabled])]:hover:bg-white
                    [&:not([disabled])]:hover:text-gray-500
                    [&:not([disabled])]:active:bg-gray-100
                    [&:not([disabled])]:active:text-gray-700
                    `,
                ghost: `
                    bg-transparent
                    text-gray-600
                    [&>*:not(svg)]:text-gray-600
                    focus:ring-2 focus:ring-gray-300
                    [&:not([disabled])]:hover:bg-gray-100
                    [&:not([disabled])]:hover:text-gray-800
                    [&:not([disabled])]:active:bg-gray-200
                    [&:not([disabled])]:active:text-gray-900
                    `,
                danger: `
                    bg-red-600
                    text-white
                    [&>*:not(svg)]:text-white
                    focus:ring-2 focus:ring-red-400 focus:border-red-700
                    [&:not([disabled])]:hover:bg-red-500
                    [&:not([disabled])]:active:bg-red-700
                    `,
            },
            size: {
                xs: 'text-sm leading-4 px-3 py-1',
                sm: 'text-sm leading-4 px-5 py-2',
                md: 'text-sm leading-5 px-6 py-2',
                lg: 'text-base py-3 px-6',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'md',
        },
    },
)

export type ButtonVariants = VariantProps<typeof buttonVariants>
