<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'
import { current, route } from 'momentum-trail'
import { RouteName } from 'momentum-trail/dist/types/router'
import { useFluidNavigation } from '@/scripts/composables/useFluidNavigation'

const { container, transitionElement, resizing } = useFluidNavigation('active-menu-item', false)

const isCurrentRoute = (routeName: RouteName) => Boolean(usePage().url && current(routeName))

defineEmits(['toggle'])
</script>

<template>
    <nav class="relative z-10 bg-white">
        <div class="px-4">
            <div ref="container" class="container grid grid-cols-5 gap-x-4">
                <MobileNavMenuItem :href="route('company:dashboard')" :active="isCurrentRoute('company:dashboard')">
                    <template #icon><RectangleGroupIcon /></template>
                    {{ $t('Dashboard') }}
                </MobileNavMenuItem>
                <MobileNavMenuItem :href="route('company:invoices.index')" :active="isCurrentRoute('company:invoices.*')">
                    <template #icon><DocumentCurrencyEuroIcon /></template>
                    {{ $t('Invoices') }}
                </MobileNavMenuItem>
                <MobileNavMenuItem :href="route('company:estimates.index')" :active="isCurrentRoute('company:estimates.*')">
                    <template #icon><DocumentTextIcon /></template>
                    {{ $t('Estimates') }}
                </MobileNavMenuItem>
                <MobileNavMenuItem :href="route('company:customers.index')" :active="isCurrentRoute('company:customers.*')">
                    <template #icon><UsersIcon /></template>
                    {{ $t('Customers') }}
                </MobileNavMenuItem>
                <MobileNavMenuItem type="button" @click="$emit('toggle')">
                    <template #icon><EllipsisHorizontalIcon /></template>
                    {{ $t('More') }}
                </MobileNavMenuItem>
            </div>
        </div>
        <div class="relative flex h-1.5 w-full">
            <div ref="transitionElement" class="absolute" :class="!resizing && 'transition-all duration-200'">
                <div class="mx-auto h-1.5 w-1/2 rounded-t-sm bg-pink-500"></div>
            </div>
        </div>
    </nav>
</template>
