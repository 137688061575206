<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'

const props = withDefaults(
    defineProps<{
        href?: string | null
        type?: string
        active?: boolean
    }>(),
    {
        href: null,
        type: 'button',
        active: false,
    },
)

const element = computed(() => (props.href ? Link : 'button'))
const dynamicProps = computed(() => (props.href ? { href: props.href } : { type: props.type }))
</script>

<template>
    <component
        :is="element"
        v-bind="dynamicProps"
        :class="[
            active
                ? 'active-tab text-pink-600'
                : 'border-transparent text-gray-500 transition-colors duration-200 hover:border-gray-300 hover:text-gray-700',
            'whitespace-nowrap border-b-2 px-4 py-4 text-sm font-medium focus:outline-pink-500 md:px-6',
        ]"
    >
        <slot />
    </component>
</template>
