<script setup>
const props = defineProps({
    href: {
        type: String,
        default: undefined,
    },
    type: {
        type: String,
        default: 'button',
    },
    active: {
        type: Boolean,
        default: false,
    },
})

const element = computed(() => (props.href ? Link : 'button'))
const dynamicProps = computed(() => (props.href ? { href: props.href } : { type: props.type }))
</script>

<template>
    <Component
        :is="element"
        v-bind="dynamicProps"
        class="flex flex-col items-center pt-4 pb-2 text-gray-600 hover:text-gray-800"
        :class="active && 'active-menu-item text-pink-500 hover:text-pink-600'"
    >
        <div class="block h-5 w-5"><slot name="icon" /></div>
        <span class="text-xs"><slot /></span>
    </Component>
</template>
