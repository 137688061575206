import { type VariantProps, cva } from 'class-variance-authority'

export { default as Avatar } from './Avatar.vue'
export { default as AvatarImage } from './AvatarImage.vue'
export { default as AvatarFallback } from './AvatarFallback.vue'

export const avatarVariant = cva(
    'inline-flex items-center justify-center font-normal text-neutral-950 select-none shrink-0 bg-neutral-100 dark:text-neutral-50 dark:bg-neutral-800 shrink-0 overflow-hidden',
    {
        variants: {
            size: {
                xs: 'h-6 w-6 text-xs',
                sm: 'h-10 w-10 text-xs',
                md: 'h-12 w-12 text-base',
                base: 'h-16 w-16 text-2xl',
                lg: 'h-24 w-24 text-2xl',
                xl: 'h-32 w-32 text-5xl',
            },
            shape: {
                circle: 'rounded-full',
                square: 'rounded-md',
            },
            ring: {
                none: '',
                1: 'ring-1 ring-white',
                2: 'ring-2 ring-white',
            },
        },
    },
)

export type AvatarVariants = VariantProps<typeof avatarVariant>
