<script setup lang="ts">
import { usePageTitle } from '@/scripts/composables/usePageTitle'
import { useUrlDefaultsWatcher } from '@/scripts/composables/useUrlDefaultsWatcher'

useUrlDefaultsWatcher()

const pageTitle = usePageTitle()
</script>

<template>
    <div class="flex min-h-screen bg-white text-gray-900 antialiased">
        <Head :title="pageTitle" />

        <div class="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
            <slot />
        </div>

        <div class="relative hidden w-0 flex-1 lg:block">
            <img
                class="absolute inset-0 h-full w-full object-cover"
                src="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=4470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
            />
        </div>

        <!-- Global notifications -->
        <AppNotifications />
    </div>
</template>
