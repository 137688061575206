<script setup lang="ts">
import { usePageTitle } from '@/scripts/composables/usePageTitle'
import { useUrlDefaultsWatcher } from '@/scripts/composables/useUrlDefaultsWatcher'
import SliptreeLogo from '@/svg/brand/sliptree-logo.svg'

useUrlDefaultsWatcher()

const pageTitle = usePageTitle()
</script>

<template>
    <div class="container sm:px-8">
        <Head :title="pageTitle" />

        <!-- Page Header -->
        <Link href="/" class="my-8 block mx-4 md:mx-0">
            <SliptreeLogo class="h-12 w-auto" />
        </Link>

        <!-- Flash Messages  -->
        <FlashMessages />

        <!-- Page Content -->
        <main class="my-6" scroll-region>
            <slot />
        </main>

        <!-- Global notifications -->
        <AppNotifications />
    </div>
</template>
