<script setup lang="ts">
import type { Component, HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'
import { computed } from 'vue'

const props = withDefaults(
    defineProps<{
        class?: HTMLAttributes['class']
        responsive?: boolean
        as?: string | Component
    }>(),
    {
        class: undefined,
        responsive: true,
        as: 'div',
    },
)

const defaultClasses = computed(() => {
    return [props.responsive ? 'w-full sm:rounded' : 'rounded', 'bg-white shadow']
})
</script>

<template>
    <Component :is="as" :class="cn(defaultClasses, props.class)">
        <slot />
    </Component>
</template>
