<script setup lang="ts">
import { usePageTitle } from '@/scripts/composables/usePageTitle'
import { useUrlDefaultsWatcher } from '@/scripts/composables/useUrlDefaultsWatcher'
import AppBanners from '@/views/layouts/Partials/AppBanners.vue'
import { Modal } from '@vendor/emargareten/inertia-modal'

useUrlDefaultsWatcher()

const pageTitle = usePageTitle()
</script>

<template>
    <div class="">
        <Head :title="pageTitle" />

        <AppTopNav />

        <AppBanners />

        <div class="mx-auto max-w-7xl sm:px-8">
            <!-- Page Content -->
            <main class="my-6">
                <slot />
            </main>
        </div>

        <!-- Route-based modals -->
        <Modal />

        <!-- Global notifications -->
        <AppNotifications />
    </div>
</template>
