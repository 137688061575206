<script setup lang="ts">
import ErrorNotificationIcon from './ErrorNotificationIcon.vue'
import SuccessNotificationIcon from './SuccessNotificationIcon.vue'
import { ActionButtonData, NotificationType } from '@/scripts/types/generated'
import { Notification } from '@/scripts/types/notiwind'
import DefaultNotificationIcon from '@/views/components/Shared/Notification/DefaultNotificationIcon.vue'
import InfoNotificationIcon from '@/views/components/Shared/Notification/InfoNotificationIcon.vue'
import NotificationCloseButton from '@/views/components/Shared/Notification/NotificationCloseButton.vue'
import WarningNotificationIcon from '@/views/components/Shared/Notification/WarningNotificationIcon.vue'

const notificationIcons = {
    [NotificationType.SUCCESS]: SuccessNotificationIcon,
    [NotificationType.ERROR]: ErrorNotificationIcon,
    [NotificationType.INFO]: InfoNotificationIcon,
    [NotificationType.WARNING]: WarningNotificationIcon,
    [NotificationType.DEFAULT]: DefaultNotificationIcon,
}

const notificationTextColors = {
    [NotificationType.SUCCESS]: 'text-gray-900',
    [NotificationType.ERROR]: 'text-red-600',
    [NotificationType.INFO]: 'text-blue-600',
    [NotificationType.WARNING]: 'text-amber-600',
    [NotificationType.DEFAULT]: 'text-gray-900',
}

function handleActionEvent(action: ActionButtonData) {
    if (action.type === 'reload') {
        window.location.reload()
    }
}
</script>

<template>
    <Notification
        v-slot="{ notifications, close, hovering }"
        enter="transform ease-out duration-300 transition"
        enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
        enter-to="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
        move="transition duration-500"
        move-delay="delay-300"
    >
        <div
            v-for="notification in notifications"
            :key="notification.id"
            class="pointer-events-auto w-full max-w-md overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            role="alert"
            @mouseover="hovering(notification.id, true)"
            @mouseleave="hovering(notification.id, false, notification.timeout)"
        >
            <div class="p-4" dusk="toast-notification">
                <div v-if="notification.condensed" class="flex items-center">
                    <div class="flex w-0 flex-1 justify-between items-center gap-3">
                        <div
                            v-md="notification.message ?? notification.title"
                            class="w-0 flex-1 text-sm font-semibold"
                            :class="notificationTextColors[notification.type]"
                        />

                        <div v-if="notification.actions?.length" class="gap-2">
                            <Button
                                v-for="action in notification.actions"
                                :key="action.label"
                                v-bind="action.props"
                                @click="handleActionEvent(action)"
                            >
                                {{ action.label }}
                            </Button>
                        </div>
                    </div>
                    <NotificationCloseButton @click="close(notification.id)" />
                </div>

                <div v-else class="flex items-start">
                    <div class="flex-shrink-0">
                        <Component :is="notificationIcons[notification.type]" class="h-6 w-6" aria-hidden="true" />
                    </div>
                    <div class="ml-3 w-0 flex-1 flex flex-col gap-1">
                        <p v-if="notification.title" class="text-sm font-semibold" :class="notificationTextColors[notification.type]">
                            {{ notification.title }}
                        </p>

                        <div v-if="notification.message" v-md="notification.message" class="text-sm text-gray-500" />

                        <div v-if="notification.actions?.length" class="flex gap-2 mt-1 items-end">
                            <Button
                                v-for="action in notification.actions"
                                :key="action.label"
                                v-bind="action.props"
                                @click="handleActionEvent(action)"
                            >
                                {{ action.label }}
                            </Button>
                        </div>
                    </div>
                    <NotificationCloseButton @click="close(notification.id)" />
                </div>
            </div>
        </div>
    </Notification>
</template>
