<script setup lang="ts">
import type { RouteName } from 'momentum-trail/dist/types/router'
import { CustomerPermissions, EstimatePermissions, InvoicePermissions, ProductPermissions } from '@/scripts/types/generated'
import { can, canOneOf } from '@/scripts/utils/permissions'
import { usePage } from '@inertiajs/vue3'
import { current, route } from 'momentum-trail'

const isCurrentRoute = (routeName: RouteName) => usePage().url && current(routeName)
</script>

<template>
    <nav id="nav-menu" class="mt-8 flex-1 pr-8">
        <NavMenuItem :href="route('company:dashboard')" :active="isCurrentRoute('company:dashboard')">
            <template #icon>
                <RectangleGroupIcon />
            </template>
            {{ $t('Dashboard') }}
        </NavMenuItem>
        <NavMenuItem
            v-if="canOneOf(InvoicePermissions.VIEW, InvoicePermissions.VIEW_OWN)"
            :href="route('company:invoices.index')"
            :active="isCurrentRoute('company:invoices.*')"
        >
            <template #icon>
                <DocumentCurrencyEuroIcon />
            </template>
            {{ $t('Invoices') }}
        </NavMenuItem>
        <NavMenuItem
            v-if="canOneOf(EstimatePermissions.VIEW, EstimatePermissions.VIEW_OWN)"
            :href="route('company:estimates.index')"
            :active="isCurrentRoute('company:estimates.*')"
        >
            <template #icon>
                <DocumentTextIcon />
            </template>
            {{ $t('Estimates') }}
        </NavMenuItem>
        <NavMenuItem
            v-if="can(CustomerPermissions.VIEW)"
            :href="route('company:customers.index')"
            :active="isCurrentRoute('company:customers.*')"
        >
            <template #icon>
                <UsersIcon />
            </template>
            {{ $t('Customers') }}
        </NavMenuItem>
        <NavMenuItem
            v-if="can(ProductPermissions.VIEW)"
            :href="route('company:products.index')"
            :active="isCurrentRoute('company:products.*')"
        >
            <template #icon>
                <CubeIcon />
            </template>
            {{ $t('Products') }}
        </NavMenuItem>
    </nav>
</template>
