<script setup lang="ts">
import { AvatarImage, type AvatarImageProps } from 'radix-vue'

const props = withDefaults(defineProps<AvatarImageProps & { contained?: boolean }>(), {
    contained: false,
})
</script>

<template>
    <AvatarImage v-bind="props" class="h-full w-full" :class="contained ? 'object-contain' : 'object-cover'" />
</template>
