<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'

defineOptions({
    inheritAttrs: false,
})

const props = withDefaults(
    defineProps<{
        href?: string
        method?: string
        as?: string
        external?: boolean
    }>(),
    {
        href: undefined,
        method: undefined,
        as: undefined,
        external: false,
    },
)

const element = computed(() => (props.href ? (props.external || props.as === 'a' ? 'a' : Link) : 'button'))
</script>

<template>
    <li>
        <Component
            :is="element"
            :href="href"
            :method="method"
            :as="as"
            class="group flex w-full items-center px-8 py-2 text-left text-gray-600 hover:text-gray-900"
            :target="external && '_blank'"
            v-bind="$attrs"
        >
            <span class="mr-4 h-5 w-5 flex-none text-gray-400">
                <slot name="icon" />
            </span>
            <span class="flex flex-col">
                <span class="flex-auto">
                    <slot />
                </span>
                <span class="text-xs text-gray-400">
                    <slot name="helptext" />
                </span>
            </span>
            <span class="ml-4 mr-0 h-5 w-5 flex-none text-gray-600 group-hover:text-gray-800">
                <slot name="secondary-icon" />
            </span>
        </Component>
    </li>
</template>
