import { createNotifier, NotificationGroup, defineNotificationComponent } from 'notiwind'
import { NotificationType } from '@/scripts/types/generated'

export type NotificationSchema = {
    group: string
    title?: string | null
    message: string
    type: NotificationType
    condensed?: boolean
}

export const notify = createNotifier<NotificationSchema>()
export const Notification = defineNotificationComponent<NotificationSchema>()
export { NotificationGroup }
