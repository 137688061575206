<script setup lang="ts">
import type { FlipOptions, Placement } from '@floating-ui/vue'
import { Menu, MenuItems } from '@headlessui/vue'
import { Float } from '@headlessui-float/vue'

withDefaults(
    defineProps<{
        placement?: Placement
        offset?: number
        shift?: number
        transitionOnClose?: boolean
    }>(),
    {
        placement: 'bottom-end',
        offset: 4,
        shift: 4,
        transitionOnClose: true,
    },
)

const flipOptions: Partial<FlipOptions> = {
    crossAxis: true,
    padding: 5,
}
</script>

<template>
    <Menu v-slot="menuProps" as="div" class="inline-block text-left" @click.stop>
        <Float
            :placement
            :offset
            :shift
            :flip="flipOptions"
            enter="transition duration-100 ease-out"
            enter-from="scale-95 opacity-0"
            enter-to="scale-100 opacity-100"
            :leave=" transitionOnClose ? 'transition duration-75 ease-in' : ''"
            leave-from="scale-100 opacity-100"
            leave-to="scale-95 opacity-0"
            tailwindcss-origin-class
            z-index="20"
        >
            <slot name="button" v-bind="menuProps">
                <DropdownButton />
            </slot>

            <MenuItems class="z-20 min-w-48 rounded-md bg-white shadow-lg ring-1 py-1 ring-black ring-opacity-5 focus:outline-none">
                <slot v-bind="menuProps" />
            </MenuItems>
        </Float>
    </Menu>
</template>
