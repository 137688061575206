<script setup lang="ts">
import { useFluidNavigation } from '@/scripts/composables/useFluidNavigation'

const { container, transitionElement, resizing, positionActiveTabMarker } = useFluidNavigation('active-tab')

withDefaults(
    defineProps<{
        justified?: boolean
    }>(),
    {
        justified: false,
    },
)

defineExpose({
    reposition: positionActiveTabMarker,
})
</script>

<template>
    <div>
        <div class="border-b border-gray-200">
            <nav
                ref="container"
                class="relative -mb-px flex overflow-x-auto"
                :aria-label="$t('Tabs')"
                :class="{ 'justify-around': justified }"
            >
                <slot />
                <span
                    ref="transitionElement"
                    class="absolute bottom-0 h-0.5 bg-pink-500"
                    :class="!resizing && 'duration-600 transition-all'"
                ></span>
            </nav>
        </div>
    </div>
</template>
