<script setup lang="ts">
import { FocusTrap } from '@headlessui/vue'
import { router } from '@inertiajs/vue3'
import { ref, computed } from 'vue'
import SliptreeLogo from '@/svg/brand/sliptree-logo.svg'

const showMobileProfileMenu = ref(false)

const focusTrapFeatures = computed(() =>
    showMobileProfileMenu.value
        ? FocusTrap.features.All & ~FocusTrap.features.FocusLock & ~FocusTrap.features.RestoreFocus
        : FocusTrap.features.None,
)

router.on('start', () => (showMobileProfileMenu.value = false))
</script>

<template>
    <!-- Desktop -->
    <div class="hidden lg:flex lg:shrink-0">
        <div class="flex w-[300px] flex-col overflow-y-auto">
            <NavProfile class="shrink-0" />

            <NavMenu class="flex-grow" />

            <footer class="m-12 shrink-0">
                <SliptreeLogo class="mx-auto h-6 w-auto fill-current text-gray-400" />
            </footer>
        </div>
    </div>

    <!-- Mobile -->
    <div class="fixed bottom-0 left-0 right-0 z-30 lg:hidden">
        <div class="shadow-reverse z-10 -mx-8 border-t border-gray-200 bg-white px-8">
            <!-- TODO: using FocusTrap here is buggy, probably because https://github.com/tailwindlabs/headlessui/discussions/2416 {@itambek 2023-004-05} -->
            <FocusTrap :features="focusTrapFeatures">
                <Transition
                    v-show="showMobileProfileMenu"
                    class="fixed inset-0 bg-gray-100"
                    enter-active-class="transition ease-in-out duration-500 sm:duration-700"
                    enter-from-class="translate-y-full"
                    enter-to-class="translate-y-0"
                    leave-active-class="transition ease-in-out duration-500 sm:duration-700"
                    leave-from-class="translate-y-0"
                    leave-to-class="translate-y-full"
                >
                    <div v-show="showMobileProfileMenu" class="flex flex-col justify-between">
                        <NavProfile :collapsible-nav="false" />
                        <SliptreeLogo class="mx-auto mb-24 h-8 w-auto fill-current text-gray-400" />
                    </div>
                </Transition>

                <MobileNavMenu @toggle="showMobileProfileMenu = !showMobileProfileMenu" />
            </FocusTrap>
        </div>
    </div>
</template>
