<script setup lang="ts">
import type { RouteName } from 'momentum-trail/dist/types/router'
import {
    CompanyPermissions,
    EmailTemplatePermissions,
    FinancialAccountPermissions,
    MemberPermissions,
    SettingsPermissions,
    TaxPermissions,
} from '@/scripts/types/generated'
import { can } from '@/scripts/utils/permissions'
import { usePage } from '@inertiajs/vue3'
import { current, route } from 'momentum-trail'

const isCurrentRoute = (routeName: RouteName): boolean => (usePage().url ? current(routeName) : false)
</script>

<template>
    <TabNav>
        <TabButton v-if="can(CompanyPermissions.UPDATE)" :href="route('company:account.edit')" :active="isCurrentRoute('company:account.edit')">
            {{ $t('Company profile') }}
        </TabButton>
        <TabButton v-if="can(SettingsPermissions.UPDATE)" :href="route('company:appearance.edit')" :active="isCurrentRoute('company:appearance.edit')">
            {{ $t('Appearance') }}
        </TabButton>
        <TabButton v-if="can(SettingsPermissions.UPDATE)" :href="route('company:settings.edit')" :active="isCurrentRoute('company:settings.edit')">
            {{ $t('Settings') }}
        </TabButton>
        <TabButton v-if="can(TaxPermissions.VIEW)" :href="route('company:taxes.index')" :active="isCurrentRoute('company:taxes.index')">
            {{ $t('Taxes') }}
        </TabButton>
        <TabButton
            v-if="can(MemberPermissions.VIEW)"
            :href="route('company:account.members.index')"
            :active="isCurrentRoute('company:account.members.index')"
            dusk="member-settings-tab"
        >
            {{ $t('Members') }}
        </TabButton>
        <TabButton
            v-if="can(FinancialAccountPermissions.VIEW)"
            :href="route('company:financial-accounts.index')"
            :active="isCurrentRoute('company:financial-accounts.index')"
            dusk="banking-settings-tab"
        >
            {{ $t('Banking') }}
        </TabButton>
        <TabButton
            v-if="can(EmailTemplatePermissions.VIEW)"
            :href="route('company:email-templates.index')"
            :active="isCurrentRoute('company:email-templates.index')"
            dusk="email-settings-tab"
        >
            {{ $t('Emails') }}
        </TabButton>
        <TabButton
            v-if="can(CompanyPermissions.MANAGE_BILLING)"
            :href="route('company:billing')"
            :active="isCurrentRoute('company:billing')"
            dusk="billing-settings-tab"
        >
            {{ $t('Billing') }}
        </TabButton>
    </TabNav>
</template>
