<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'
import { current, route } from 'momentum-trail'
import { RouteName } from 'momentum-trail/dist/types/router'
import { computed, ref, nextTick } from 'vue'
import { CompanyPermissions, ProductPermissions, SuperAdminPermissions } from '@/scripts/types/generated'
import { can } from '@/scripts/utils/permissions'

const props = defineProps({
    collapsibleNav: {
        type: Boolean,
        default: true,
    },
})

const user = usePage().props.auth.user
const company = computed(() => usePage().props.auth.company)
const profileMenu = ref(null)
const businessMenu = ref(null)
const navToggle = ref(null)
const subNavToggle = ref(null)
const navOpen = ref(!props.collapsibleNav)
const subNavOpen = ref(false)
const height = ref(navOpen.value ? 'auto' : 0)

const style = computed(() => (height.value === 'auto' ? '' : `height:${height.value}px`))

const toggleNav = () => {
    navOpen.value = !navOpen.value

    height.value = navOpen.value ? profileMenu.value.offsetHeight : 0

    if (!navOpen.value) {
        subNavOpen.value = false
    }
}

const toggleSubNav = () => {
    subNavOpen.value = !subNavOpen.value
    nextTick(() => {
        height.value = subNavOpen.value ? businessMenu.value.offsetHeight : profileMenu.value.offsetHeight
    })
}

const subNavToggleCallback = () => {
    if (subNavOpen.value) {
        /* This moves the focus to the beginning of the now visible subnav */
        /* Disabled as it triggers an error and focus management seems to work without this { @itambek 2022-11-10} */
        /* subNavToggle.value.blur() */
    } else if (navOpen.value) {
        /* This moves the focus to the expected location when navigating from subnav back to main nav */
        navToggle.value.focus()
        navToggle.value.blur()
    }
}

const isCurrentCompany = companyId => {
    return companyId === company?.value.id
}

const isCurrentRoute = (routeName: RouteName) => usePage().url && current(routeName)
</script>

<template>
    <nav class="max-w-full overflow-hidden">
        <!-- TODO: Try using a Disclosure component here (HeadlessUI) [ST-178] {@krlmhkl 2022-11-04} -->
        <Component
            :is="collapsibleNav ? 'button' : 'div'"
            ref="navToggle"
            type="button"
            class="group z-10 block w-full bg-gray-50 px-9 pb-8 pt-8 transition-colors duration-1000 hover:bg-white"
            :aria-expanded="navOpen"
            aria-controls="profile-menu"
            dusk="nav-profile"
            @click="collapsibleNav && toggleNav()"
        >
            <div class="flex shrink-0 flex-col items-center gap-3">
                <UserAvatar size="lg" />
                <p class="text-gray-700">{{ user.name }}</p>
            </div>

            <div class="mt-4 flex items-center gap-2 justify-center">
                <div>
                    <p class="font-bold">{{ company.name }}</p>
                </div>
                <div>
                    <ChevronDownIcon
                        v-if="collapsibleNav"
                        class="h-5 w-5 text-gray-400 transition-all duration-200 group-hover:text-gray-700"
                        :class="{ 'rotate-180': navOpen }"
                    />
                </div>
            </div>
        </Component>

        <div
            class="-mx-1 overflow-hidden border-b border-gray-200 bg-gray-100 px-1 text-sm shadow-inner transition-all duration-200"
            :class="{ 'h-0': !navOpen && collapsibleNav }"
            :style="style"
        >
            <div
                class="grid grid-flow-col grid-cols-2-full transition duration-200"
                :class="{ '-translate-x-full': subNavOpen }"
                @transitionend="subNavToggleCallback()"
            >
                <ul
                    ref="profileMenu"
                    class="border-t border-gray-200 py-2 transition duration-200 ease-linear"
                    :class="{ invisible: !navOpen || subNavOpen }"
                >
                    <!-- TODO: Try using a Disclosure component here (HeadlessUI) [ST-178] {@krlmhkl 2022-11-04} -->
                    <ProfileMenuItem
                        ref="subNavToggle"
                        as="button"
                        aria-controls="business-menu"
                        :aria-expanded="subNavOpen"
                        @click="toggleSubNav()"
                    >
                        <template #icon><BriefcaseIcon /></template>
                        {{ $t('Switch or create business') }}
                        <template #secondary-icon><ChevronRightIcon class="text-gray-400" /></template>
                    </ProfileMenuItem>
                    <ProfileMenuItem :href="route('company:user.profile.show')" dusk="nav-profile-personal-settings">
                        <template #icon><UserIcon /></template>
                        {{ $t('Personal settings') }}
                        <template #helptext>{{ $t('Profile, authentication...') }}</template>
                    </ProfileMenuItem>
                    <ProfileMenuItem v-if="can(CompanyPermissions.UPDATE)" :href="route('company:account.edit')">
                        <template #icon><CogIcon /></template>
                        {{ $t('Business settings') }}
                        <template #helptext>{{ $t('Defaults, taxes, billing...') }}</template>
                    </ProfileMenuItem>
                    <ProfileMenuItem v-if="can(SuperAdminPermissions.VIEW_ADMIN)" :href="route('filament.admin.pages.dashboard')" external>
                        <template #icon><ShieldCheckIcon /></template>
                        Access Super Admin
                    </ProfileMenuItem>
                    <ProfileMenuItem href="https://help.sliptree.com" external>
                        <template #icon><LifebuoyIcon /></template>
                        {{ $t('Help') }}
                    </ProfileMenuItem>
                    <ProfileMenuItem :href="route('logout')" method="post" as="button">
                        <template #icon><ArrowRightOnRectangleIcon /></template>
                        {{ $t('Sign out') }}
                    </ProfileMenuItem>
                </ul>

                <ul
                    ref="businessMenu"
                    class="border-t border-gray-200 py-2 transition duration-200 ease-linear"
                    :class="!subNavOpen && 'invisible h-0'"
                >
                    <ProfileMenuItem as="button" @click="toggleSubNav()">
                        <template #icon><ChevronLeftIcon /></template>
                        {{ $t('Back') }}
                    </ProfileMenuItem>
                    <div v-for="userCompany in user.companies" :key="userCompany.slug">
                        <ProfileMenuItem
                            :href="route('company:dashboard', { company: userCompany.slug })"
                            as="a"
                            :class="{ 'font-semibold': isCurrentCompany(userCompany.id) }"
                        >
                            <span>
                                {{ userCompany.name }}
                            </span>
                            <template #secondary-icon>
                                <CheckIcon v-if="isCurrentCompany(userCompany.id)" />
                            </template>
                        </ProfileMenuItem>
                    </div>
                    <li class="my-2 border-t border-gray-200"></li>
                    <ProfileMenuItem :href="route('companies.create')">
                        <template #icon>
                            <PlusCircleIcon />
                        </template>
                        {{ $t('Add another business') }}
                    </ProfileMenuItem>
                </ul>
            </div>
        </div>

        <div id="mobile-nav-menu" class="mt-8 flex-1 pr-8 lg:hidden text-left">
            <NavMenuItem
                v-if="can(ProductPermissions.VIEW)"
                :href="route('company:products.index')"
                :active="isCurrentRoute('company:products.*')"
            >
                <template #icon>
                    <CubeIcon />
                </template>
                {{ $t('Products') }}
            </NavMenuItem>
        </div>
    </nav>
</template>
