<script setup lang="ts">
import { computed, HTMLAttributes } from 'vue'
import { Link } from '@inertiajs/vue3'
import { Primitive, type PrimitiveProps } from 'radix-vue'
import { type ButtonVariants, buttonVariants } from '.'
import { cn } from '@/lib/utils'

interface Props extends PrimitiveProps {
    href?: string
    type?: 'button' | 'submit' | 'reset'
    plain?: boolean
    loading?: boolean
    disabled?: boolean
    variant?: ButtonVariants['variant']
    size?: ButtonVariants['size']
    class?: HTMLAttributes['class']
}

const props = withDefaults(defineProps<Props>(), {
    href: undefined,
    type: 'button',
    plain: false,
    variant: 'default',
    loading: undefined,
    disabled: undefined,
    size: undefined,
    class: undefined,
})

const as = computed(() => (props.href ? (props.plain ? 'a' : Link) : 'button'))
const element = computed(() => (props.href ? 'anchor' : 'button'))
const dynamicProps = computed(() => (props.href ? { href: props.href } : { type: props.type }))

const isDisabled = computed(() => {
    return props.disabled || props.loading
})
</script>

<template>
    <Primitive
        :as
        :data-loading="loading ? true : null"
        :as-child="asChild"
        :class="cn(buttonVariants({ variant, size, element }), props.class)"
        v-bind="dynamicProps"
        :disabled="isDisabled ? true : null"
        :aria-disabled="isDisabled ? true : null"
        class="relative"
    >
        <div
            data-loading-indicator
            class="absolute transition-opacity inset-0 flex items-center justify-center rounded-full"
            :class="{
                'opacity-0': !loading,
                'opacity-100': loading,
            }"
        >
            <LoadingAnimation />
        </div>
        <slot />
    </Primitive>
</template>
