<script setup lang="ts">
import type { BasePageProps } from '@/scripts/types/pages'
import { usePageTitle } from '@/scripts/composables/usePageTitle'
import { useUrlDefaultsWatcher } from '@/scripts/composables/useUrlDefaultsWatcher'
import AppBanners from '@/views/layouts/Partials/AppBanners.vue'
import { usePage } from '@inertiajs/vue3'
import { Modal } from '@vendor/emargareten/inertia-modal'
import CompanyNav from '../pages/Companies/Partials/CompanyNav.vue'

useUrlDefaultsWatcher()

const company = usePage<BasePageProps>().props.auth.company

const pageTitle = usePageTitle()
</script>

<template>
    <div :key="company.id" class="flex h-screen overflow-hidden">
        <Head :title="pageTitle" />

        <!-- App Navigation -->
        <AppNav />

        <div class="-mt-10 flex-1 overflow-auto pb-20 pt-10 shadow-xl lg:pb-0">
            <AppBanners />

            <div class="container sm:px-8">
                <!-- Page Content -->
                <main class="my-6" scroll-region>
                    <CompanyNav />
                    <slot />
                </main>
            </div>
        </div>

        <!-- Route-based modals -->
        <Modal />

        <!-- Global notifications -->
        <AppNotifications />
    </div>
</template>
