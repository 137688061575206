import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

/**
 A wrapper around a string that has been marked as safe ("trusted"). **When
 rendered in HTML, Vue will not perform any escaping.**

 Note:

 This does not *make* the string safe; it means that some code in your
 application has *marked* it as safe using.

 If a string contains user inputs or other untrusted data, you must sanitize
 the string before using the `htmlSafe` method. Otherwise your code is
 vulnerable to [Cross-Site Scripting][xss]. There are many open source
 sanitization libraries to choose from, both for front end and server-side
 sanitization.

 This class is based on SafeString from Ember.js.

 @see https://api.emberjs.com/ember/release/classes/safestring/
 */
export class SafeString {
    private readonly __string: string

    constructor(string: string) {
        this.__string = string
    }

    toString(): string {
        return `${this.__string}`
    }
}
