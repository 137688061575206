<script setup lang="ts">
import type { NotificationData } from '@/scripts/types/generated'
import type { BasePageProps } from '@/scripts/types/pages'
import { NotificationType } from '@/scripts/types/generated'
import { NotificationGroup, notify } from '@/scripts/types/notiwind'
import Notifications from '@/views/components/Shared/Notification/Notifications.vue'
import { usePage } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import { watch } from 'vue'

function addNotification(notification: NotificationData) {
    const timeout = notification.timeout || 4000 // 4s by default

    notify(
        {
            group: 'global',
            condensed: false,
            actions: [],
            ...notification,
            timeout, // so that the timeout is available when rendering the notification
        },
        timeout,
    )
}

let pendingVersionUpdate = false

// watch the page.version for changes and trigger a notification if updated
watch(
    () => usePage<BasePageProps>().version,
    (newVersion, oldVersion) => {
        const newVersionWithoutLocale = newVersion?.split('-')[0]
        const oldVersionWithoutLocale = oldVersion?.split('-')[0]

        // avoid showing the notification if the actual version has not changed - just the locale
        if (newVersionWithoutLocale === oldVersionWithoutLocale) {
            return
        }

        // some pages may not allow update notifications to be shown - one example is the
        // document editor, where we do not want any update notifications to interfere
        // with the user's current actions
        if (!usePage<BasePageProps>().props.allowVersionUpdateNotification) {
            pendingVersionUpdate = true

            return
        }

        addVersionUpdateNotification()
    },
)

// watch the page.props.allowVersionUpdateNotification for changes and trigger a notification if a version update is pending
watch(
    () => usePage<BasePageProps>().props.allowVersionUpdateNotification,
    (allow) => {
        if (allow && pendingVersionUpdate) {
            addVersionUpdateNotification()
        }
    },
)

function addVersionUpdateNotification() {
    addNotification({
        group: 'system',
        type: NotificationType.INFO,
        message: [trans('An update to Sliptree is available.'), trans('Refresh the page to update.')].join('\n\n'),
        actions: [{ label: trans('Refresh'), type: 'reload', props: { variant: 'secondary', size: 'sm' } }],
        condensed: true,
        timeout: -1,
    })

    pendingVersionUpdate = false
}

// Using a watcher on the page props ensures that we catch both notifications from initial page loads and from
// Inertia router events
watch(
    () => usePage<BasePageProps>().props.flash.notification,
    (notification) => {
        if (!notification) {
            return
        }

        setTimeout(() => addNotification(notification), 0) // not sure why, but without the timeout, the notification is not shown
    },
    { immediate: true },
)
</script>

<template>
    <Teleport to="body">
        <NotificationGroup group="global">
            <div class="pointer-events-none fixed inset-0 z-[60] flex items-start justify-end p-6 px-4 py-6">
                <div class="w-full max-w-sm space-y-4">
                    <Notifications />
                </div>
            </div>
        </NotificationGroup>
        <NotificationGroup group="system">
            <div class="pointer-events-none fixed inset-0 z-[60] flex items-end justify-end p-6 px-4 py-6">
                <div class="w-full max-w-md space-y-4">
                    <Notifications />
                </div>
            </div>
        </NotificationGroup>
    </Teleport>
</template>
