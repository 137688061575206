import { usePage } from '@inertiajs/vue3'
import { ref, onMounted, watchEffect, onUnmounted } from 'vue'

/*
|--------------------------------------------------------------------------
| Fluid transition between routes
|--------------------------------------------------------------------------
|
| This composable adds a transition element to navigation routes / tabs.
| To utilize the transition effect, the component must be used in a presistent layout,
| otherwise the page reload will negate the transition.
|
|
*/
export const useFluidNavigation = (activeElementClassName: string, referenceContainer = true) => {
    const resizing = ref(false)
    //Use container ref to reference the wrapper for the navigation elements
    const container = ref<HTMLElement | null>(null)
    //use the transitionElement ref to reference the element that will transition on route change
    const transitionElement = ref<HTMLElement | null>(null)

    const positionActiveTabMarker = () => {
        if (!container.value || !transitionElement.value) {
            return
        }

        const containerRect = container.value.getBoundingClientRect()
        const rect = container.value.getElementsByClassName(activeElementClassName)[0]?.getBoundingClientRect()
        const position = referenceContainer ? (rect?.left || 0) - (containerRect.left || 0) : rect?.left || 0

        transitionElement.value.style.left = position + 'px'
        transitionElement.value.style.width = (rect?.width || 0) + 'px'
    }

    const handleResize = () => {
        resizing.value = true
        positionActiveTabMarker()
        setTimeout(() => (resizing.value = false), 200)
    }

    onMounted(() => {
        window.addEventListener('resize', handleResize)

        watchEffect(() => {
            usePage().url // this will trigger the watcher when page URL changes
            setTimeout(positionActiveTabMarker, 50)
        })
    })

    onUnmounted(() => {
        window.removeEventListener('resize', handleResize)
    })

    return {
        container,
        transitionElement,
        resizing,
        positionActiveTabMarker,
    }
}
