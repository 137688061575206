import MarkdownIt from 'markdown-it'
import mila from 'markdown-it-link-attributes'

const md = new MarkdownIt({
    breaks: true,
})

function isExternalLink(href: string) {
    try {
        if (href.startsWith('docs:')) {
            return false
        }

        const host = typeof window !== 'undefined' ? window.location.host : ''

        return new URL(href).hostname !== host
    }
    catch {
        return false
    }
}

/**
 * Plugin to convert `docs:articleId` links to beacon article modals
 *
 * @param md
 */
function docsArticlePlugin(md: MarkdownIt) {
    const defaultRender
        = md.renderer.rules.link_open
        || function (tokens, idx, options, env, self) {
            return self.renderToken(tokens, idx, options)
        }

    md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
        const token = tokens[idx]
        const hrefIndex = token.attrIndex('href')

        if (token.attrs && hrefIndex >= 0) {
            const href = token.attrs[hrefIndex][1]

            if (href.startsWith('docs:')) {
                const articleId = href.split(':')[1]

                token.attrs[hrefIndex][1] = '#'
                token.attrPush(['data-beacon-article-modal', articleId])
            }
        }

        return defaultRender(tokens, idx, options, env, self)
    }
}

md.use(docsArticlePlugin)

md.use(mila, [
    {
        matcher(href: string) {
            return isExternalLink(href)
        },
        attrs: {
            target: '_blank',
            rel: 'noopener',
            class: 'underline inline-flex items-center after:pl-0.5 after:content-[\'_↗\'] text-pink-500 hover:text-pink-400',
        },
    },
    {
        matcher(href: string) {
            return !isExternalLink(href)
        },
        attrs: {
            class: 'underline inline-flex items-center',
        },
    },
])

export default md
