<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { UserData } from '@/scripts/types/generated'
import { BasePageProps } from '@/scripts/types/pages'
import { Avatar, AvatarFallback, AvatarImage } from '@/views/components/ui/avatar'

const props = withDefaults(
    defineProps<{
        user?: UserData
    }>(),
    {
        user: () => usePage<BasePageProps>().props.auth.user,
    },
)

const backgroundClass = ['bg-gray-500', 'bg-pink-500', 'bg-teal-500', 'bg-blue-500', 'bg-violet-500', 'bg-red-500', 'bg-amber-500']

const backgroundColor = computed(() =>
    !props.user.profile_photo_path
        ? backgroundClass[(props.user.name ?? props.user.email).length % backgroundClass.length]
        : 'bg-transparent',
)
</script>

<template>
    <Avatar :class="backgroundColor">
        <AvatarImage v-if="user.profile_photo_path" :src="user.profile_photo_url" alt="avatar" />
        <AvatarFallback class="text-white">{{ user.initials }}</AvatarFallback>
    </Avatar>
</template>
