export interface ActionButtonData {
    label: string
    variant: string
    type: string | null
    props: { [key: string]: any }
    events: { [key: string]: any }
}
export interface ActivityData {
    batch_uuid: string | null
    event: string
    subject_type: string
    subject_id: number | null
    description: string | null
    event_count: number | null
    properties: { [key: string]: any }
}
export interface AddressData {
    full_address: string | null
    address: string | null
    address_2: string | null
    city: string | null
    state: string | null
    postal_code: string | null
    country: string | null
}
export enum AmountType { NET_AMOUNT = 'net_amount', GROSS_AMOUNT = 'gross_amount' };
export interface AppearanceSettings {
    primary_color: string
    logo_alignment: string
    theme: string
}
export interface BankAccountData {
    type: FinancialAccountType
    name: string | null
    bank_name: string | null
    account_number: string | null
    sort_code: string | null
    routing_number: string | null
    bank_code: string | null
    email: string | null
    iban: string | null
    bic: string | null
    public: boolean
    id?: number | null
    ulid?: string | null
}
export interface BannerData {
    message: string
    type: BannerType
    dismissible: boolean
    id: string | number | null
}
export enum BannerType { SUCCESS = 'success', ERROR = 'error', WARNING = 'warning', INFO = 'info', DEFAULT = 'default' };
export interface BillBerryIntegrationSettings {
    sendingEnabled: boolean
    receivingEnabled: boolean
    receivingOperator: string | null
}
export enum CalculationType { NET_PRICE = 'net_price', NET_AMOUNT = 'net_amount', GROSS_PRICE = 'gross_price', GROSS_AMOUNT = 'gross_amount' };
export interface CalendarOptionsData {
    firstDayOfWeek: number
    monthNames: MonthNamesData
    dayNames: DayNamesData
}
export enum CardBrand { AMEX = 'amex', DINERS = 'diners', EFTPOS_AU = 'eftpos_au', JCB = 'jcb', UNIONPAY = 'unionpay', MASTERCARD = 'mastercard', VISA = 'visa', UNKNOWN = 'unknown' };
export interface CommentData {
    id: number | null
    ulid: string | null
    author: UserData | null
    body: string
    created_at?: string | null
    updated_at?: string | null
}
export enum CommentPermissions { VIEW = 'view-comments', CREATE = 'create-comments', UPDATE_OWN = 'update-own-comments', UPDATE = 'update-comments', DELETE_OWN = 'delete-own-comments', DELETE = 'delete-comments' };
export interface CompanyData {
    logo: LogoData | null
    initials?: string
    id?: number | null
    ulid?: string | null
    name?: string | null
    slug?: string | null
    address?: string | null
    address_2?: string | null
    city?: string | null
    state?: string | null
    postal_code?: string | null
    country?: string | null
    reg_nr?: string | null
    vat_id?: string | null
    email?: string | null
    phone?: string | null
    website?: string | null
    billing_email?: string | null
    membership?: any | null
}
export enum CompanyPermissions { VIEW = 'view-companies', CREATE = 'create-companies', UPDATE = 'update-companies', DELETE = 'delete-companies', TRANSFER_OWNERSHIP = 'transfer-ownership', MANAGE_BILLING = 'manage-billing' };
export interface CompanySettings {
}
export interface CustomerData {
    initials?: string
    locale_for_humans?: string
    late_fee_rate_for_humans?: string
    payment_terms_for_humans?: string
    expiration_terms_for_humans?: string
    accepts_e_invoices?: boolean
    e_invoice_operator?: EInvoiceOperator
    id?: number | null
    ulid?: string | null
    external_id?: string | null
    name?: string | null
    address?: string | null
    address_2?: string | null
    city?: string | null
    state?: string | null
    postal_code?: string | null
    country?: string | null
    reg_nr?: string | null
    vat_id?: string | null
    email?: string | null
    phone?: string | null
    website?: string | null
    notes?: string | null
    invoice_payment_terms?: number | null
    estimate_expiration_terms?: number | null
    currency?: string | null
    locale?: string | null
    late_fee_rate?: number | null
    created_at: any | null
    updated_at: any | null
    deleted_at: any | null
}
export enum CustomerPermissions { VIEW = 'view-customers', CREATE = 'create-customers', UPDATE = 'update-customers', DELETE = 'delete-customers', RESTORE = 'restore-customers', FORCE_DELETE = 'force-delete-customers', IMPORT = 'import-customers', EXPORT = 'export-customers' };
export enum DateRange { ALL_TIME = '', TODAY = 'today', YESTERDAY = 'yesterday', THIS_WEEK = 'this_week', LAST_WEEK = 'last_week', LAST_2_WEEKS = 'last_2_weeks', THIS_MONTH = 'this_month', LAST_30_DAYS = 'last_30_days', LAST_90_DAYS = 'last_90_days', LAST_MONTH = 'last_month', LAST_MONTH_TO_DATE = 'last_month_to_date', LAST_6_MONTHS = 'last_6_months', LAST_12_MONTHS = 'last_12_months', THIS_YEAR = 'this_year', LAST_YEAR = 'last_year', CUSTOM = 'custom' };
export interface DayNamesData {
    min: Array<string>
    short: Array<string>
    long: Array<string>
}
export enum DocumentNumberResetSequence { NEVER = 'never', YEARLY = 'yearly', MONTHLY = 'monthly', DAILY = 'daily' };
export interface EInvoiceData {
    document_type: SalesDocumentType
    due_at_for_humans?: string
    due_at_diff_for_humans?: string
    total_paid_for_humans?: string
    total_paid_for_humans_with_currency?: string
    total_unpaid?: number
    total_unpaid_for_humans?: string
    total_unpaid_for_humans_with_currency?: string
    overdue?: boolean
    partial?: boolean
    id?: number | null
    ulid?: string | null
    company?: CompanyData | null
    customer?: CustomerData | null
    payments?: Array<InvoicePaymentData>
    title?: string | null
    number?: string | null
    reference_number?: string | null
    currency?: string | null
    notes?: string | null
    payment_method?: PaymentMethod | null
    locale?: string | null
    due_at?: any | null
    payment_terms?: number | null
    late_fee_rate?: number | null
    total_paid?: number
    sent?: boolean
    viewed?: boolean
    paid?: boolean
    credited?: boolean
    status?: InvoiceState
    type: InvoiceType | null
    items?: Array<LineItemData>
    tax_lines?: Array<TaxLineData>
    issued_at?: any | null
    source_documents?: Array<LinkedDocumentData> | null
    target_documents?: Array<LinkedDocumentData> | null
    user?: SalesDocumentUserData | null
    financial_accounts?: Array<any> | null
    settings?: SalesDocumentSettingsData | null
    exchange_rate?: number | null
    subtotal?: number
    total_discount?: number
    total_net?: number
    total_tax?: number
    total?: number
    issued_at_for_humans?: string
    total_for_humans?: string
    total_for_humans_with_currency?: string
    total_net_for_humans?: string
    total_net_for_humans_with_currency?: string
    total_tax_for_humans?: string
    total_tax_for_humans_with_currency?: string
    total_discount_for_humans?: string
    total_discount_for_humans_with_currency?: string
    subtotal_for_humans?: string
    subtotal_for_humans_with_currency?: string
    download_url?: string
    render_url?: string | null
    public_url?: string
    title_with_number?: string
    locked?: boolean
    editLockMessage?: string | null
    deleteLockMessage?: string | null
}
export enum EInvoiceOperator { NONE = 'none', BILLBERRY = 'billberry', FINBITE = 'finbite', TELEMA = 'telema', E_ARVELDAJA = 'earveldaja', UNIFIEDPOST = 'unifiedpost', AMPHORA = 'amphora' };
export interface EInvoiceSentEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface EeIdPropertyData {
    auth_type: string
    id_code: string
    first_name: string
    last_name: string
}
export interface EmailTemplateData {
    id: number | null
    ulid: string | null
    document_type: SalesDocumentType | null
    name: string | null
    content: string | null
    locale: string | null
    subject: string | null
    attach_pdf: boolean
    bcc: string | null
}
export enum EmailTemplatePermissions { VIEW = 'view-email-templates', CREATE = 'create-email-templates', UPDATE = 'update-email-templates', DELETE = 'delete-email-templates' };
export interface EstimateData {
    document_type: SalesDocumentType
    expires_at_for_humans?: string
    expires_at_diff_for_humans?: string
    expired?: string
    id?: number | null
    ulid?: string | null
    company?: CompanyData | null
    items?: Array<LineItemData>
    title?: string | null
    reference_number?: string | null
    currency?: string | null
    notes?: string | null
    locale?: string | null
    expires_at?: any | null
    expiration_terms?: number | null
    sent?: boolean
    viewed?: boolean
    status?: EstimateState
    tax_lines?: Array<TaxLineData>
    customer?: CustomerData | null
    number?: string | null
    issued_at?: any | null
    source_documents?: Array<LinkedDocumentData> | null
    target_documents?: Array<LinkedDocumentData> | null
    user?: SalesDocumentUserData | null
    financial_accounts?: Array<any> | null
    settings?: SalesDocumentSettingsData | null
    exchange_rate?: number | null
    subtotal?: number
    total_discount?: number
    total_net?: number
    total_tax?: number
    total?: number
    issued_at_for_humans?: string
    total_for_humans?: string
    total_for_humans_with_currency?: string
    total_net_for_humans?: string
    total_net_for_humans_with_currency?: string
    total_tax_for_humans?: string
    total_tax_for_humans_with_currency?: string
    total_discount_for_humans?: string
    total_discount_for_humans_with_currency?: string
    subtotal_for_humans?: string
    subtotal_for_humans_with_currency?: string
    download_url?: string
    render_url?: string | null
    public_url?: string
    title_with_number?: string
    locked?: boolean
    editLockMessage?: string | null
    deleteLockMessage?: string | null
}
export enum EstimatePermissions { VIEW_OWN = 'view-own-estimates', VIEW = 'view-estimates', CREATE = 'create-estimates', UPDATE_OWN = 'update-own-estimates', UPDATE = 'update-estimates', DELETE_OWN = 'delete-own-estimates', DELETE = 'delete-estimates', IMPORT = 'import-estimates', EXPORT = 'export-estimates', SEND_OWN = 'send-own-estimates', SEND = 'send-estimates' };
export interface EstimateSettings {
    expiration_terms: number
    notes: string | null
    disabled_line_item_fields: Array<any>
    display_created_by: boolean
    next_number: number
    number_format: string
    number_padding: number
    number_reset: DocumentNumberResetSequence
}
export interface EstimateState {
}
export enum ExpirationTerm { SEVENDAYS = 'net_7', FOURTEENDAYS = 'net_14', THIRTYDAYS = 'net_30', SIXTYDAYS = 'net_60', NINETYDAYS = 'net_90', NEVER = 'none', CUSTOM = 'custom' };
export interface ExternalIdentityData {
    external_service: string
    external_id: string
    created_at: any
    updated_at: any
    payload: any
}
export enum FinancialAccountPermissions { VIEW = 'view-financial-accounts', CREATE = 'create-financial-accounts', UPDATE = 'update-financial-accounts', DELETE = 'delete-financial-accounts', IMPORT = 'import-financial-accounts', EXPORT = 'export-financial-accounts' };
export enum FinancialAccountType { SEPA_BANK_ACCOUNT = 'sepa', UK_BANK_ACCOUNT = 'uk_bank', US_BANK_ACCOUNT = 'us_bank', BANK_ACCOUNT = 'bank', PAYPAL = 'paypal' };
export interface GeneralSettings {
    locale: string
    timezone: string
    currency: string
    auto_save_line_items_as_products: boolean
    use_taxes: boolean
    display_price_type_in_labels: boolean
    price_display: PriceDisplay
    display_created_by: boolean
}
export interface InvitationData {
    id: number | null
    ulid: string | null
    email: string | null
    role: Role | null
    user: UserData | null
    company: CompanyData | null
}
export enum InvitationPermissions { VIEW = 'view-invitations', CREATE = 'create-invitations', UPDATE = 'update-invitations', DELETE = 'delete-invitations' };
export interface InvoiceData {
    document_type: SalesDocumentType
    due_at_for_humans?: string
    due_at_diff_for_humans?: string
    total_paid_for_humans?: string
    total_paid_for_humans_with_currency?: string
    total_unpaid?: number
    total_unpaid_for_humans?: string
    total_unpaid_for_humans_with_currency?: string
    overdue?: boolean
    partial?: boolean
    id?: number | null
    ulid?: string | null
    company?: CompanyData | null
    customer?: CustomerData | null
    payments?: Array<InvoicePaymentData>
    title?: string | null
    number?: string | null
    reference_number?: string | null
    currency?: string | null
    notes?: string | null
    payment_method?: PaymentMethod | null
    locale?: string | null
    due_at?: any | null
    payment_terms?: number | null
    late_fee_rate?: number | null
    total_paid?: number
    sent?: boolean
    viewed?: boolean
    paid?: boolean
    credited?: boolean
    status?: InvoiceState
    type: InvoiceType | null
    items?: Array<LineItemData>
    tax_lines?: Array<TaxLineData>
    issued_at?: any | null
    source_documents?: Array<LinkedDocumentData> | null
    target_documents?: Array<LinkedDocumentData> | null
    user?: SalesDocumentUserData | null
    financial_accounts?: Array<any> | null
    settings?: SalesDocumentSettingsData | null
    exchange_rate?: number | null
    subtotal?: number
    total_discount?: number
    total_net?: number
    total_tax?: number
    total?: number
    issued_at_for_humans?: string
    total_for_humans?: string
    total_for_humans_with_currency?: string
    total_net_for_humans?: string
    total_net_for_humans_with_currency?: string
    total_tax_for_humans?: string
    total_tax_for_humans_with_currency?: string
    total_discount_for_humans?: string
    total_discount_for_humans_with_currency?: string
    subtotal_for_humans?: string
    subtotal_for_humans_with_currency?: string
    download_url?: string
    render_url?: string | null
    public_url?: string
    title_with_number?: string
    locked?: boolean
    editLockMessage?: string | null
    deleteLockMessage?: string | null
}
export interface InvoicePaymentData {
    date_for_humans?: string
    amount_for_humans?: string
    id?: number | null
    ulid?: string | null
    invoice?: any | null
    date: string
    amount: number
    payment_method: PaymentMethod
    description: string | null
    currency: string | null
}
export enum InvoicePermissions { VIEW_OWN = 'view-own-invoices', VIEW = 'view-invoices', CREATE = 'create-invoices', UPDATE_OWN = 'update-own-invoices', UPDATE = 'update-invoices', DELETE_OWN = 'delete-own-invoices', DELETE = 'delete-invoices', IMPORT = 'import-invoices', EXPORT = 'export-invoices', SEND_OWN = 'send-own-invoices', SEND = 'send-invoices', SEND_OWN_E_INVOICE = 'send-own-e-invoices', SEND_E_INVOICE = 'send-e-invoices' };
export interface InvoiceSettings {
    reference_number_base: SalesDocumentReferenceNumberBase
    payment_terms: number
    notes: string | null
    use_late_fee: boolean
    late_fee_rate: number
    disabled_line_item_fields: Array<any>
    display_created_by: boolean
    next_number: number
    number_format: string
    number_padding: number
    number_reset: DocumentNumberResetSequence
}
export interface InvoiceState {
}
export enum InvoiceType { INVOICE = 'invoice', CREDIT_NOTE = 'credit_note' };
export interface InvoicesSummaryData {
    total_net: number
    total_tax: number
    total: number
    byCurrency: Array<TotalsByCurrencyData>
    hasTotalsInNonDefaultCurrencies: boolean
    taxes: Array<TaxSummaryData>
}
export interface LineItemData {
    product: ProductData | null
    tax: TaxData | null
    id: number | null
    ulid: string | null
    product_id: number | null
    product_external_id: string | null
    name: string | null
    description: string | null
    unit: string | null
    net_price: number | null
    gross_price: number | null
    quantity: number | null
    discount_rate: number | null
    tax_id: number | null
    subtotal: number | null
    discount_amount: number | null
    net_amount: number | null
    tax_amount: number | null
    gross_amount: number | null
    calculate_price_from: CalculationType | null
}
export interface LineItemDeletedEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface LinkedDocumentData {
    id: number | null
    ulid: string | null
    title: string | null
    number: string | null
    document_type: string | null
    type: InvoiceType | null
    issued_at: string | null
}
export interface LogoData {
    src: string | null
    type: string | null
}
export enum MemberPermissions { VIEW = 'view-members', CREATE = 'create-members', UPDATE = 'update-members', DELETE = 'delete-members' };
export interface MetricData {
    name: string
    value: string
    value_for_humans: string
    description: string
    tip: string | null
    change: string | null
    color: string | null
    url: string | null
}
export interface MonthNamesData {
    short: Array<string>
    long: Array<string>
}
export interface NotificationData {
    type: NotificationType
    message: string
    title: string | null
    timeout: number | null
    condensed: boolean | null
    actions: Array<ActionButtonData> | null
}
export enum NotificationType { SUCCESS = 'success', ERROR = 'error', WARNING = 'warning', INFO = 'info', DEFAULT = 'default' };
export interface PayPalAccountData {
    type: FinancialAccountType
    logo_url: string
    name: string | null
    email: string | null
    public: boolean
    id?: number | null
    ulid?: string | null
}
export enum PaymentMethod { BANK_TRANSFER = 'bank_transfer', CARD = 'card', CHECK = 'check', CASH = 'cash', OTHER = 'other' };
export enum PaymentTerm { ONRECIPT = 'net_0', SEVENDAYS = 'net_7', FOURTEENDAYS = 'net_14', THIRTYDAYS = 'net_30', SIXTYDAYS = 'net_60', NINETYDAYS = 'net_90', NONE = 'none', CUSTOM = 'custom' };
export enum PriceDisplay { SPLIT = 'split', NET = 'net', GROSS = 'gross' };
export interface ProductData {
    net_price_for_humans?: string
    gross_price_for_humans?: string
    unit_for_humans?: string
    unit_long_for_humans?: string
    id?: number | null
    ulid?: string | null
    external_id?: string | null
    name?: string
    description?: string | null
    unit?: string | null
    tax_id?: number
    net_price?: number | null
    gross_price?: number | null
    calculate_price_from?: CalculationType
}
export enum ProductPermissions { VIEW = 'view-products', CREATE = 'create-products', UPDATE = 'update-products', DELETE = 'delete-products', IMPORT = 'import-products', EXPORT = 'export-products' };
export enum Role { OWNER = 'owner', ADMIN = 'admin', MEMBER = 'member', VIEWER = 'viewer', SALES_REP = 'sales_rep' };
export interface SalesDocumentBouncedEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface SalesDocumentCommentEntryData {
    event_key: string
    comment: CommentData
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface SalesDocumentCreatedEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface SalesDocumentData {
    id?: number | null
    document_type: SalesDocumentType
    items?: Array<LineItemData>
    tax_lines?: Array<TaxLineData>
    customer?: CustomerData | null
    number?: string | null
    issued_at?: any | null
    source_documents?: Array<LinkedDocumentData> | null
    target_documents?: Array<LinkedDocumentData> | null
    user?: SalesDocumentUserData | null
    financial_accounts?: Array<any> | null
    settings?: SalesDocumentSettingsData | null
    exchange_rate?: number | null
    subtotal?: number
    total_discount?: number
    total_net?: number
    total_tax?: number
    total?: number
    issued_at_for_humans?: string
    total_for_humans?: string
    total_for_humans_with_currency?: string
    total_net_for_humans?: string
    total_net_for_humans_with_currency?: string
    total_tax_for_humans?: string
    total_tax_for_humans_with_currency?: string
    total_discount_for_humans?: string
    total_discount_for_humans_with_currency?: string
    subtotal_for_humans?: string
    subtotal_for_humans_with_currency?: string
    download_url?: string
    render_url?: string | null
    public_url?: string
    title_with_number?: string
    locked?: boolean
    editLockMessage?: string | null
    deleteLockMessage?: string | null
}
export interface SalesDocumentDeliveredEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface SalesDocumentEmailData {
    salesDocument: any
    to: Array<any> | string
    cc: Array<any> | string | null
    bcc: Array<any> | string | null
    subject: string | null
    message: string | null
    attach_pdf: boolean
    locale: string | null
}
export interface SalesDocumentPaymentAddedEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface SalesDocumentPaymentDeletedEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface SalesDocumentPaymentUpdatedEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export enum SalesDocumentReferenceNumberBase { DISABLED = 'disabled', INVOICE = 'invoice', CUSTOMER = 'customer' };
export interface SalesDocumentSentEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface SalesDocumentSettings {
    display_created_by: boolean
    next_number: number
    number_format: string
    number_padding: number
    number_reset: DocumentNumberResetSequence
}
export interface SalesDocumentSettingsData {
    price_display?: PriceDisplay
    display_price_type_in_labels?: boolean
    display_created_by?: boolean
    disabled_line_item_fields?: Array<any>
}
export enum SalesDocumentTheme { MODERN = 'modern', ELEGANT = 'elegant' };
export interface SalesDocumentTimelineEntryData {
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    event_key: string
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export enum SalesDocumentType { INVOICE = 'invoice', ESTIMATE = 'estimate' };
export interface SalesDocumentUpdatedEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface SalesDocumentUserData {
    id: number | null
    ulid: string | null
    name: string | null
}
export interface SalesDocumentViewedEntryData {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface SaveSalesDocumentOptionsData {
    saveCustomerProfile: boolean
    saveCompanyProfile: boolean
    updateSettings: boolean
    markAs: string | null
    throwOnInvalidTransition: boolean
    throwOnNumberConflict: boolean
    saveLineItemsAsProducts: boolean | null
}
export interface SepaBankAccountData {
    type: FinancialAccountType
    name: string | null
    bank_name: string | null
    iban: string | null
    bic: string | null
    public: boolean
    id?: number | null
    ulid?: string | null
}
export interface SettingsData {
    ui: UserUiSettings
    general: GeneralSettings
    appearance?: AppearanceSettings | null
    invoice?: InvoiceSettings | null
    estimate?: EstimateSettings | null
    billberry?: BillBerryIntegrationSettings | null
}
export enum SettingsPermissions { VIEW = 'view-settings', UPDATE = 'update-settings' };
export interface SubscriptionData {
    onTrial: boolean | null
    hasExpiredTrial: boolean | null
    subscribed: boolean | null
    endsAt: any | null
    canceled: boolean | null
    onGracePeriod: boolean | null
    hasIncompletePayment: boolean | null
    isBillable: boolean | null
    trialEndsAt: any | null
    totalInvoicesCurrentYear: number
}
export enum SuperAdminPermissions { IMPERSONATE_USERS = 'impersonate-users', VIEW_PULSE = 'view-pulse', VIEW_HORIZON = 'view-horizon', VIEW_ADMIN = 'view-admin', VIEW_RENDERED_DOCS = 'view-rendered-docs' };
export interface TargetDocumentLinked {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface TargetDocumentUnlinked {
    event_key: string
    created_at_for_humans?: string
    created_at_diff_for_humans?: string
    message?: string
    changes?: Array<string>
    status_message?: string
    id: number | null
    batch_uuid: string | null
    log_name: string | null
    subject_type: string | null
    subject_id: number | null
    causer_type: string | null
    causer_id: number | null
    causer: UserData | null
    event: string | null
    description: string | null
    properties: { [key: string]: any } | null
    created_at: any | null
    updated_at: any | null
    activities: Array<ActivityData>
    subject: SalesDocumentData | null
}
export interface TaxData {
    rate_for_humans?: string
    is_locked?: boolean
    id: number | null
    ulid: string | null
    name?: string
    rate?: number | null
    external_id?: string | null
    is_active?: boolean
    is_built_in?: boolean
    is_default?: boolean
}
export interface TaxLineData {
    tax: TaxData | null
    id: number | null
    ulid: string | null
    tax_id: number | null
    net_amount: number
    tax_amount: number
}
export enum TaxPermissions { VIEW = 'view-taxes', CREATE = 'create-taxes', UPDATE = 'update-taxes', DELETE = 'delete-taxes', IMPORT = 'import-taxes', EXPORT = 'export-taxes' };
export interface TaxSummaryData {
    tax_id: number
    tax_rate: number | null
    tax_name: string | null
    net_amount: number
    net_amount_in_currency: number
    tax_amount: number
    tax_amount_in_currency: number
    gross_amount: number
    gross_amount_in_currency: number
    byCurrency: Array<TotalsByCurrencyData>
    hasTaxesInNonDefaultCurrencies: boolean
}
export enum TaxType { VAT = 'vat', GST = 'gst', SALES_TAX = 'sales_tax', TAX = 'tax' };
export interface TaxTypeLabelsData {
    name: string
    column: string
    nameWithAmount: string
    nameWithRateOnTotal: string
    totalExcludingTax: string
    totalTaxExempt: string
    ofWhichTaxExempt: string
    ofWhichTaxable: string
}
export interface TotalsByCurrencyData {
    currency: string
    total_net: number
    total_net_in_currency: number
    total_tax: number
    total_tax_in_currency: number
    total: number
    total_in_currency: number
}
export enum TransactionPermissions { VIEW = 'view-transactions', CREATE = 'create-transactions', UPDATE = 'update-transactions', DELETE = 'delete-transactions', IMPORT = 'import-transactions', EXPORT = 'export-transactions' };
export type TransactionState = 'failed' | 'pending' | 'success' | 'timeout' | 'void'
export enum TransactionType { AUTHORIZATION = 'authorization', PAYMENT = 'payment', REFUND = 'refund' };
export interface UKBankAccountData {
    type: FinancialAccountType
    name: string | null
    bank_name: string | null
    account_number: string | null
    sort_code: string | null
    iban: string | null
    bic: string | null
    public: boolean
    id?: number | null
    ulid?: string | null
}
export interface USBankAccountData {
    type: FinancialAccountType
    name: string | null
    bank_name: string | null
    account_number: string | null
    routing_number: string | null
    bic: string | null
    public: boolean
    id?: number | null
    ulid?: string | null
}
export interface UnitData {
    id: string
    short: string
    long: string
}
export interface UnitGroupData {
    label: string
    units: Array<UnitData>
}
export interface UserData {
    id: number | null
    ulid: string | null
    name: string
    initials: string | null
    email: string
    last_login_ip: string | null
    profile_photo_path: string | null
    profile_photo_url: string | null
    two_factor_enabled: boolean | null
    locale: string | null
    membership?: any | null
    last_login_at?: string | null
    created_at?: string | null
    updated_at?: string | null
    companies: Array<CompanyData> | null
}
export interface UserIdentityData {
    id: number
    ulid: string | null
    user_id: number
    provider: UserIdentityProvider
    properties: Array<any>
    created_at?: string | null
    updated_at?: string | null
}
export enum UserIdentityProvider { EEID = 'eeid', GOOGLE = 'google', FACEBOOK = 'facebook', APPLE = 'apple' };
export interface UserSettings {
}
export interface UserUiSettings {
    dashboard_sales_summary_date_range: DateRange
    invoice_list_show_metrics_and_summary: boolean
}
