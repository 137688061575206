<script setup lang="ts">
import { computed, ref } from 'vue'
import { usePage } from '@inertiajs/vue3'
import axios from 'axios'
import { route } from 'momentum-trail'
import { BasePageProps } from '@/scripts/types/pages'

const page = usePage<BasePageProps>()
const banner = page.props.flash.banner
const dismissed = ref(false)

const showBanner = computed(() => banner?.message && !dismissed.value)

function dismiss() {
    dismissed.value = true

    if (banner?.id) {
        axios.delete(route('notifications.destroy', { notification: banner.id }))
    }
}
</script>

<template>
    <div
        v-if="showBanner"
        class="flex items-center gap-x-6 bg-gray-700 px-6 py-2.5 sm:px-3.5"
        :class="[!banner?.dismissible && 'justify-around', banner?.dismissible && 'sm:before:flex-1']"
    >
        <div v-md="banner?.message" class="prose text-sm text-white prose-a:text-white prose-a:hover:text-white"></div>
        <div v-if="banner?.dismissible" class="flex flex-1 justify-end">
            <button type="button" class="-m-3 p-3 focus-visible:outline-offset-[-4px]" @click="dismiss">
                <span class="sr-only">{{ $t('Dismiss') }}</span>
                <XMarkIcon class="h-5 w-5 text-white" aria-hidden="true" />
            </button>
        </div>
    </div>
</template>
