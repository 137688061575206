<script setup lang="ts">
import { MenuItem } from '@headlessui/vue'
import { Link } from '@inertiajs/vue3'
import { computed, nextTick } from 'vue'

defineOptions({
    inheritAttrs: false,
})

const props = withDefaults(
    defineProps<{
        href?: string | null
        as?: string
        size?: 'sm' | 'md' | 'lg'
        external?: boolean
    }>(),
    {
        href: null,
        as: undefined,
        size: 'md',
        external: false,
    },
)

const element = computed(() => (props.href ? (props.external || props.as === 'a' ? 'a' : Link) : 'button'))

/** @see https://headlessui.com/v1/vue/menu#closing-menus-manually */
function maybeClose(close: () => void) {
    if (props.href) {
        nextTick(close)
    }
}
</script>

<template>
    <MenuItem v-slot="{ active, disabled, close }" :disabled="Boolean($attrs.disabled)">
        <Component
            v-bind="$attrs"
            :is="element"
            :as="as"
            :href="href"
            :type="!href && 'button'"
            :target="external ? '_blank' : undefined"
            class="flex w-full items-center gap-2 text-left" :class="[
                disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer',
                active && !disabled ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                size === 'sm' && 'text-xs py-1 px-2',
                size === 'md' && 'text-sm py-2 px-4',
                size === 'lg' && 'text-md py-3 px-5',
            ]"
            @mouseup="maybeClose(close)"
        >
            <slot />
        </Component>
    </MenuItem>
</template>
