import { usePage } from '@inertiajs/vue3'
import { BasePageProps } from '@/scripts/types/pages'

export function can(permission: string): boolean {
    return usePage<BasePageProps>().props.auth?.permissions?.includes(permission)
}

export function canOneOf(...permissions: string[]): boolean {
    return permissions.some(can)
}

export function canAllOf(...permissions: string[]): boolean {
    return permissions.every(can)
}
