import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import objectSupport from 'dayjs/plugin/objectSupport'

dayjs.extend(isoWeek)
dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(objectSupport)

const dateFormatters: Record<string, Intl.DateTimeFormat> = {}
const dayNames: Record<string, string[]> = {}
const monthNames: Record<string, string[]> = {}
const tokenFormats: Record<string, string[]> = {}

const getDateFormatter = (locale: string, format = {}) => {
    const cacheKey = `${locale}_${JSON.stringify(format)}`

    if (!dateFormatters[cacheKey]) {
        dateFormatters[cacheKey] = new Intl.DateTimeFormat(locale, format)
    }

    return dateFormatters[cacheKey]
}

const localizeDayNames = (locale: string, format: string) => {
    const formatter = getDateFormatter(locale, { weekday: format, timeZone: 'UTC' })

    return [1, 2, 3, 4, 5, 6, 7]
        .map(day => {
            const dd = day < 10 ? `0${day}` : day

            return new Date(`2017-01-${dd}T00:00:00+00:00`)
        })
        .map(date => formatter.format(date))
}

const localizeMonthNames = (locale: string, format: string) => {
    const formatter = getDateFormatter(locale, { month: format, timeZone: 'UTC' })

    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        .map(month => {
            const mm = month < 10 ? `0${month}` : month

            return new Date(`2017-${mm}-01T00:00:00+00:00`)
        })
        .map(date => formatter.format(date))
}

export const formats: Record<string, Intl.DateTimeFormatOptions> = {
    'numeric-long': { day: '2-digit', month: '2-digit', year: 'numeric' },
    'numeric-month': { day: '2-digit', month: 'numeric', year: 'numeric' },
    'numeric-day': { day: 'numeric', month: '2-digit', year: 'numeric' },
    'numeric-short': { day: 'numeric', month: 'numeric', year: 'numeric' },
}

export const getLocalizedTokenFormat = (locale: string, format: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat(locale, format)
        .formatToParts(new Date(2011, 1, 1)) // use specific date to ensure both short and long formats are taken into account
        .map(token => {
            switch (token.type) {
                case 'day':
                    return token.value.length === 1 ? 'D' : 'DD'

                case 'month':
                    return token.value.length === 1 ? 'M' : 'MM'

                case 'year':
                    return 'YYYY'

                default:
                    return token.value
            }
        })
        .join('')
}

export const parseLocalizedDate = (locale: string, value: string | Date, fallback = new Date()) => {
    if (value instanceof Date) {
        return value
    }

    value = value.trim()

    // a 4-digit number is considered a year, try parsing as ISO string
    if (value.match(/^\d{4}$/)) {
        const res = dayjs(value)

        if (res.isValid()) {
            return res.toDate()
        }
    }

    // 1-2 digit number is considered a date of the current month and year
    if (value.match(/^\d{1,2}$/)) {
        const res = dayjs({ day: value })

        if (res.isValid()) {
            return res.toDate()
        }
    }

    // try to parse a localized date, starting with the most specific format
    for (const tokenFormat of getLocalizedTokenFormats(locale)) {
        const res = dayjs(value, tokenFormat)

        if (res.isValid()) {
            return res.toDate()
        }
    }

    // try to parse as ISO string
    const isoDate = dayjs(value)

    return isoDate.isValid() ? isoDate.toDate() : fallback
}

export const getLocalizedTokenFormats = (locale: string) => {
    if (!tokenFormats[locale]) {
        tokenFormats[locale] = []

        for (const [, format] of Object.entries(formats)) {
            tokenFormats[locale].push(getLocalizedTokenFormat(locale, format))
        }

        for (const [, format] of Object.entries(formats)) {
            const { year, ...formatWithoutYear } = format

            tokenFormats[locale].push(getLocalizedTokenFormat(locale, formatWithoutYear))
        }
    }

    return tokenFormats[locale]
}

// TODO: consider removing these and related methods above as we're passing this data from the backend... {IT 2021-11-05}
export const getLocalizedDayNames = (locale = 'en', format = 'long') => {
    const cacheKey = `${locale}_${format}`

    if (!dayNames[cacheKey]) {
        dayNames[cacheKey] = localizeDayNames(locale, format)
    }

    return dayNames[cacheKey]
}

export const getLocalizedMonthNames = (locale = 'en', format = 'long') => {
    const cacheKey = `${locale}_${format}`

    if (!monthNames[cacheKey]) {
        monthNames[cacheKey] = localizeMonthNames(locale, format)
    }

    return monthNames[cacheKey]
}

export const parseDate = (value: string | Date | null | undefined) => {
    if (!value) {
        return null
    }

    if (value instanceof Date) {
        return value
    }

    const date = dayjs(value)

    return date.isValid() ? date.toDate() : null
}
